<template>
  <div class="gap-4 p-4">
    <Card>
      <template #title> Mot de passe </template>
      <template #subtitle>
        <div>Choisissez un mot de passe</div>
        <span class="text-sm font-italic">(8 caractères minimum)</span>
      </template>
      <template #content>
        <form @submit.prevent="nextPage" id="infos">
          <div class="p-field p-fluid">
            <label>Mot de passe</label>
            <Password v-model="password" toggleMask />
          </div>
          <div class="p-field p-fluid">
            <label>Confirmation</label>
            <Password v-model="passwordConfirm" toggleMask :feedback="false" />
          </div>
        </form>
      </template>
      <template #footer>
        <hr />
        <div class="flex justify-content-between">
          <Button
            label="Précédent"
            class="p-button-sm p-button-text p-button-secondary"
            @click="prevPage"
          />
          <Button
            label="Suivant"
            class="p-button-sm"
            type="submit"
            form="infos"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { reactive, toRefs, ref } from "vue";

export default {
  setup(props, { emit }) {
    const pageIndex = 1;
    const prevPage = () => emit("prev-page", { formData, pageIndex });
    const nextPage = () => emit("next-page", { formData, pageIndex });
    const formData = reactive({
      password: undefined,
    });

    const passwordConfirm = ref(undefined);

    return { prevPage, nextPage, ...toRefs(formData), passwordConfirm };
  },
};
</script>

<style lang="scss" scoped>
.p-field {
  margin-bottom: 0.75rem;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
